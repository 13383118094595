import React, { useState, useEffect, useCallback } from 'react';
import '../App.css';
import axios from 'axios';
import {
    Spinner,
    Table,
    Divider,
    Button,
} from "@fluentui/react-components";
import { SearchBox } from "@fluentui/react-search-preview";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

function AlertStatus() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const getAlertStatusUri = process.env.REACT_APP_ENDPOINT_URL + '/api/GetAlertStatus';
    const getAlertFireTimeUri = process.env.REACT_APP_ENDPOINT_URL + '/api/';
    const [activeAlerts, setActiveAlerts] = useState([]);
    const [inactiveAlerts, setInactiveAlerts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredActiveAlerts, setFilteredActiveAlerts] = useState([]);
    const [filteredInactiveAlerts, setFilteredInactiveAlerts] = useState([]);
    const [sortCriteria, setSortCriteria] = useState('name');

    const fetchToken = useCallback(async () => {
        const token = await instance.acquireTokenSilent({
            scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/user_impersonation`]
        });
        return token;
    }, [instance]);

    const fetchAlertFireTime = useCallback(async (alertName, token) => {
        try {
            const response = await axios.get(`${getAlertFireTimeUri}${alertName}/GetAlertFiredTime`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }, [getAlertFireTimeUri]);

    const handleSearch = event => {
        const searchString = event.target.value.toLowerCase();
        setFilteredActiveAlerts(activeAlerts.filter(alert => alert.name.toLowerCase().includes(searchString)));
        setFilteredInactiveAlerts(inactiveAlerts.filter(alert => alert.name.toLowerCase().includes(searchString)));
    };

    const sortAlerts = useCallback((alerts) => {
        return alerts.sort((a, b) => {
            if (sortCriteria === 'name') {
                return a.name.localeCompare(b.name);
            } else if (sortCriteria === 'time') {
                return new Date(a.lastFired) - new Date(b.lastFired);
            }
            return 0;
        });
    }, [sortCriteria]);

    useEffect(() => {
        if (isAuthenticated) {
            async function fetchAlerts() {
                setIsLoading(true);
                try {
                    const resToken = await fetchToken();
                    const response = await axios.get(getAlertStatusUri, { headers: { Authorization: `Bearer ${resToken.accessToken}` } });
                    const activeAlertsWithTime = await Promise.all(response.data.activeAlerts.map(async alertName => {
                        const lastFired = await fetchAlertFireTime(alertName, resToken.accessToken);
                        return { name: alertName, lastFired };
                    }));
                    const inactiveAlertsObj = response.data.inactiveAlerts.map(alertName => ({ name: alertName }));
                    setActiveAlerts(activeAlertsWithTime);
                    setInactiveAlerts(inactiveAlertsObj);
                    setFilteredActiveAlerts(activeAlertsWithTime);
                    setFilteredInactiveAlerts(inactiveAlertsObj);
                } catch (error) {
                    console.log(error);
                }
                setIsLoading(false);
            }
            fetchAlerts();
        }
    }, [isAuthenticated, fetchToken, getAlertStatusUri, fetchAlertFireTime]);

    useEffect(() => {
        setFilteredActiveAlerts(sortAlerts([...activeAlerts]));
        setFilteredInactiveAlerts(sortAlerts([...inactiveAlerts]));
    }, [sortCriteria, activeAlerts, inactiveAlerts, sortAlerts]);

    if (!isAuthenticated) {
        return (
            <span>You must authenticate to view this page</span>
        );
    }
    if (isLoading) {
        return (
            <Spinner>Loading...</Spinner>
        );
    }

    return (
        <div>
            <Divider className="divider" alignContent="start">Alerts</Divider>
            <SearchBox placeholder="Search Alerts" onChange={handleSearch}></SearchBox>
            <div className="sort-container">
                <div>Sort by:</div>
                <Button onClick={() => setSortCriteria('name')}>
                    Name
                </Button>
                <Button onClick={() => setSortCriteria('time')}>
                    Age (Oldest first)
                </Button>
            </div>
            <Divider className="divider" alignContent="start">Active Alerts</Divider>
            <Table>
                <thead>
                    <tr>
                        <th>Alert Name</th>
                        <th>Fired On</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredActiveAlerts.map(alert => (
                        <tr key={alert.name}>
                            <td>{alert.name}</td>
                            <td>{alert.lastFired}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Divider className="divider" alignContent="start">Inactive Alerts</Divider>
            <Table>
                <thead>
                    <tr>
                        <th>Alert Name</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredInactiveAlerts.map(alert => (
                        <tr key={alert.name}>
                            <td>{alert.name}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default AlertStatus;